import axios from '@/plugins/request'

export default {
  query(kw, filter, sort, pageNum, pageSize) {
    return axios({
      url: '/VehicleLicenses/',
      method: 'GET',
      params: {
        kw: kw,
        filter: encodeURI(JSON.stringify(filter)),
        sort: encodeURI(JSON.stringify(sort)),
        pageNum: pageNum,
        pageSize: pageSize
      }
    })
  },
  getById(id) {
    return axios({
      url: '/VehicleLicenses/' + id,
      method: 'GET'
    })
  },
  save(po) {
    return axios({
      url: '/VehicleLicenses/' + (po.id ? po.id : ''),
      method: po.id ? 'PUT' : 'POST',
      data: po
    })
  },
  delete(id) {
    return axios({
      url: '/VehicleLicenses/' + id,
      method: 'DELETE'
    })
  },

  getDatePoints(id) {
    return axios({
      url: '/VehicleLicenses/' + id + '/datePoints',
      method: 'GET'
    })
  },

  // customer actions================
  getCustVLs() {
    return axios({
      url: '/VehicleLicenses/myVls/',
      method: 'GET'
    })
  },
  custSaveVL(vl) {
    return axios({
      url: '/VehicleLicenses/myVls/' + (vl.id ? vl.id : ''),
      method: vl.id ? 'PUT' : 'POST',
      data: vl
    })
  },
  custDelVL(vlId) {
    return axios({
      url: '/VehicleLicenses/myVls/' + vlId,
      method: 'DELETE'
    })
  }

}
