<template>
  <div class="page-ctx">
    <VLInfo ref="vlinfo" v-model="vl" @save="updVL" />

    <div class="btns-col" style="padding:var(--mp)">
      <van-button type="primary" icon="arrow-left" block to="/cust-vl/mng" :replace="true">返 回</van-button>
    </div>
  </div>
</template>

<script>
import VLApi from '@/apis/VLApi'
import VLInfo from '@/components/VLInfo'
export default {
  components: { VLInfo },
  data() {
    return {
      vl: {}
    }
  },
  created() {
    this.getById(this.$route.params.id)
  },
  methods: {
    getById(id) {
      VLApi.getById(id).then(rsp => {
        this.vl = rsp.data
      })
    },
    updVL() {
      VLApi.custSaveVL(this.vl).then(rsp => {
        this.vl = rsp.data
        this.$toast.success('保存成功！')
      })
    }
  }
}
</script>

<style scoped>

</style>
