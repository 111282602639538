<template>
  <div>
    <van-cell-group>
      <template #title>
        <div class="cell-group-title">
          <span>{{ title }}</span>
          <span v-if="enableSwitch" @click="doAct">{{ editable?'保存':'编辑' }}</span>
          <span v-else />
        </div>
      </template>
      <van-field v-model="value.plateNo" label="号牌" :readonly="!editable" input-align="right" maxlength="20" />
      <van-field v-model="value.vehicleType" label="车辆类型" :readonly="!editable" input-align="right" maxlength="20" />
      <van-field v-model="value.owner" label="所有人" :readonly="!editable" input-align="right" maxlength="100" />
      <van-field v-model="value.address" label="住址" :readonly="!editable" input-align="right" maxlength="100" />
      <van-field v-model="value.useCharacter" label="使用性质" :readonly="!editable" input-align="right" maxlength="20" />

      <van-field v-model="value.model" label="品牌型号" :readonly="!editable" input-align="right" maxlength="20" />
      <van-field v-model="value.vin" label="VIN码" :readonly="!editable" input-align="right" maxlength="20" />
      <van-field v-model="value.engineNo" label="发动机号" :readonly="!editable" input-align="right" maxlength="20" />
      <van-field :value="value.registerDate | date" label="注册日期" :readonly="true" input-align="right" @click="!editable || pickDate('registerDate',value.registerDate)" />
      <van-field :value="value.issueDate | date" label="发证日期" :readonly="true" input-align="right" @click="!editable || pickDate('issueDate',value.issueDate)" />

      <van-field v-model="value.seal" label="发证单位" :readonly="!editable" input-align="right" maxlength="50" />
      <van-field v-model="value.seats" label="核载人数" :readonly="!editable" input-align="right" type="number" />
    </van-cell-group>

    <van-cell-group v-if="!editable" title="年审信息">
      <van-field :value="value.detectType | detectType" label="年审类型" :readonly="true" input-align="right" />
      <van-field :value="value.upline | dictBool" label="上线检测" :readonly="true" input-align="right" />
      <van-field :value="value.expireDate | date" label="有效期至" :readonly="true" input-align="right" />
    </van-cell-group>

    <van-popup v-model="datePickerShow" position="bottom">
      <van-datetime-picker v-model="dateVal" type="date" :min-date="minDate" :max-date="maxDate" @confirm="changeDate" @cancel="datePickerShow=false" />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '行驶证信息' },
    value: { type: Object, default: () => { return {} }, required: false },
    edit: { type: Boolean, default: false },
    enableSwitch: { type: Boolean, default: true }
  },
  data() {
    return {
      editable: this.edit,
      datePickerShow: false,
      dateVal: this.$moment().toDate(),
      minDate: this.$moment().add(-30, 'years').month(0).date(1).toDate(),
      maxDate: this.$moment().toDate(),
      dateField: ''
    }
  },
  methods: {
    pickDate(field, val) {
      if (this.$moment(val).isValid()) {
        this.dateVal = this.$moment(val).toDate()
      } else if (this.$moment.utc(val).isValid()) {
        this.dateVal = this.$moment.utc(val).toDate()
      } else {
        this.dateVal = this.$moment().toDate()
      }
      this.dateField = field
      this.datePickerShow = true
    },
    changeDate(val) {
      this.datePickerShow = false
      if (this.$moment.isDate(val)) {
        this.value[this.dateField] = val
      }
      const newVal = {}
      for (const key in this.value) {
        newVal[key] = this.value[key]
      }
      this.$emit('input', newVal)
    },
    doAct() {
      this.editable = !this.editable
      if (this.editable === false) {
        this.$emit('save', this.value)
      }
    }
  }
}
</script>

<style scoped>

</style>
